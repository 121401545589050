<template>
    <v-dialog v-model="dialog" max-width="800" scrollable>
        <v-card>
            <v-toolbar dark>
                <v-card-title>
                    <span> Venue notification</span>
                    <hr>
                </v-card-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                    <v-spacer></v-spacer>
                    <v-btn icon dark @click="closeDialog">
                        <i class="fa fa-close"></i>
                    </v-btn>
                </v-toolbar-items>
            </v-toolbar>

            <v-card-text>
                <v-container>

                    <v-row>
                        <v-col cols="12">
                            <p class="font-size-lg">
                                <strong> Schedule: {{ examDay.name }}</strong>
                            </p>
                            <p class="font-size-lg">
                                <strong>Venue name:</strong>
                                {{ venue.name }}
                            </p>
                            <p class="font-size-lg">
                                <strong>Venue email:</strong>
                                {{ venue.email }}
                            </p>
                            <p class="font-size-lg">
                                <strong>Venue phone:</strong>
                                {{ venue.phone }}
                            </p>
                            <p class="font-size-lg">
                                <strong>Contact person name:</strong>
                                {{ venue.contact_person_full_name ? venue.contact_person_full_name : 'NA' }}
                            </p>
                            <p class="font-size-lg">
                                <strong>Contact person phone: </strong> {{venue.contact_person_phone ? venue.contact_person_phone :'NA'}}
                            </p>
                            <p class="font-size-lg">
                                <strong>Contact person email: </strong> {{venue.contact_person_email ? venue.contact_person_email :'NA'}}
                            </p>
                            <p class="font-size-lg">
                                <strong>Address: </strong> {{venue.contact_person_full_address ? venue.contact_person_full_address :'NA'}}
                            </p>
                            <p class="font-size-lg">
                                <strong>Address line 2: </strong> {{venue.address_line_2 ? venue.address_line_2 :'N/A'}}
                            </p>
                        </v-col>
                    </v-row>

                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="btn btn-standard" text x-large @click="closeDialog">
                    Cancel
                </v-btn>
                <v-btn dark x-large :loading="loading"
                    @click="sendNotification">
                    {{ isVenueNotified ? 'Resend' : 'Send' }}  notification to venue
                </v-btn>
            </v-card-actions>
        </v-card>
        <v-dialog v-model="isDialog" hide-overlay persistent width="300">
            <v-card color="primary" dark>
                <v-card-text>
                    Please stand by
                    <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-dialog>
</template>

<script>
import VenueService from "@/services/practical-exam/venue/VenueService";
import ExamDayService from "@/services/practical-exam/exam-day/ExamDayService";

const venue = new VenueService();
const examDay = new ExamDayService();

export default {
    name: "VenueNotification",
    data() {
        return {
            loading: false,
            dialog: false,
            isDialog: false,
            edit: false,
            title: '',
            errors: [],
            examDay: '',
            venue: '',
            isVenueNotified: false
        }
    },
    methods: {
        closeDialog() {
            this.dialog = false;
            this.errors = [];
            this.resetForm();
        },

        showDialog(examDayInfo) {
            this.dialog = true;
            this.errors = [];
            this.examDay = examDayInfo;

            venue
            .show(examDayInfo.venue_id)
            .then(response => {
                this.venue = response.data.venue;
                this.checkIfVenueNotified(examDayInfo);
            });


        },

        checkIfVenueNotified(examDayInfo){
            let data = {
                venue_id: examDayInfo.venue_id,
                exam_day_id: examDayInfo.id
            }

            venue
            .checkIfVenueNotified(data)
            .then(response => {
                if(response.data.status == 'notified'){
                    this.isVenueNotified = true;
                }
            })
            .catch((err) => {
                this.isVenueNotified = false;
            });
        },

        sendNotification() {

            this.$confirm({
                message: `Are you sure? `,
                button: {
                    no: "No",
                    yes: "Yes",
                },
                callback: (confirm) => {
                    if (confirm) {
                        this.loading = true;
                        examDay
                        .sendNotificationToVenue(this.examDay.id)
                        .then((response) => {
                            this.$snotify.success("Notification sent to venue");
                            this.$emit('refresh');
                            this.closeDialog();
                        })
                        .catch((err) => {
                            this.$snotify.error("Oops something went wrong");
                            this.closeDialog();

                        }).finally(() => {
                            this.loading = false;
                        });
                    }
                },
            });
        },

        resetForm() {
            this.errors = [];
        },
    },
}
</script>