<template>
  <v-dialog
      v-model="dialog"
      max-width="800"
      scrollable
  >
    <v-card>
      <v-toolbar dark>
        <v-card-title>
          <span> Enroller notification </span>
          <hr>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDialog">
            <i class="fa fa-close"></i>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text>
        <v-container>
          <v-row>
            <strong> Schedule: {{ examDay.name }}</strong>
            <table class="table">
              <thead>
                <tr class="px-3">
                  <th class="px-3">Name</th>
                  <th class="px-3">Notified at</th>
                  <th class="px-3">Notification preference</th>
                  <th class="px-3">Status</th>
                  <th class="pr-3 text-center">Options</th>
                </tr>
              </thead>
              <tbody>
              <template>
                <tr>
                    <td colspan="5" v-if="isLoading">
                        <v-skeleton-loader type="table-row-divider@2"></v-skeleton-loader>
                    </td>
                </tr>
                <tr v-if="!isLoading && notifications.length > 0" v-for="item in notifications">
                  <td class="px-3">
                    <div class="">
                      <v-checkbox :label=item.full_name v-if="!item.notification_sent" v-model="accountholder_ids" :value="item.owner_id"></v-checkbox>
                      <span class="font-weight-medium" v-else>
                          {{item.full_name}}
                        </span>
                    </div>
                  </td>
                  <td class="px-2">
                    <span class="badge badge-warning" v-if="item.notification_sent">Notified</span>
                    <span class="badge badge-warning" v-else>Not notified</span>
                  </td>
                  <td>
                    {{item.notification_preference}}
                  </td>
                  <td class="px-2">
                    {{item.notification_sent ? item.notification_time : 'NA'}}
                  </td>
                  <td class="pr-0 text-center">
                    <template>
                      <b-dropdown
                                  size="sm"
                                  variant="link"
                                  toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                                  no-caret
                                  right
                                  no-flip
                      >
                        <template v-slot:button-content>
                          <i class="ki ki-bold-more-hor"></i>
                        </template>
                        <!--begin::Navigation-->
                        <div class="navi navi-hover min-w-md-250px" >

                          <b-dropdown-text tag="div" class="navi-item">
                            <a @click="resendNotificationToAccountHolder(item.owner_id)" class="navi-link">
                                <span class="navi-icon">
                                      <i class="fas fa-bell"></i>
                                </span>
                              <span class="navi-text">Notify enroller</span>
                            </a>
                          </b-dropdown-text>

                        </div>
                        <!--end::Navigation-->
                      </b-dropdown>
                    </template>
                  </td>
                </tr>
                <tr v-show="!isLoading && notifications.length < 1">
                  <td colspan="4">No enrolers available</td>
                </tr>
              </template>
              </tbody>
            </table>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            text
            x-large
            @click="closeDialog">
          Cancel
        </v-btn>
        <v-btn v-if="!sendToAllNotification && notifications.length>0"
           dark
               x-large
            :loading="loading"
               @click="sendNotification"

        >
          Resend notification to all enroller
        </v-btn>
        <v-btn v-if="sendToAllNotification && notifications.length > 0 && accountholder_ids.length=== 0"
               dark
               x-large
               :loading="loading"
               @click="sendNotification"
        >
          Send notification to all enrollers
        </v-btn>
        <v-btn v-if="accountholder_ids && accountholder_ids.length > 0 && notifications.length>0"
               dark
               x-large
               :loading="loading"
               @click="sendNotification('selected_send')"
        >
          Send notification to these enrollers
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-dialog
        v-model="isDialog"
        hide-overlay
        persistent
        width="300"
    >
      <v-card
          color="primary"
          dark
      >
        <v-card-text>
          Please stand by
          <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import ExamDayService from "@/services/practical-exam/exam-day/ExamDayService";
const examDay=new ExamDayService();

export default {
  name: "AccountHolderNotification",
  validations: {
    location: {
      name: { required },
      type: { required },
      is_active: { required }
    }
  },
  data() {
    return{
      loading: false,
      isLoading: true,
      currentUser:{},
      dialog: false,
      isDialog: false,
      edit: false,
      title: '',
      errors: [],
      notifications: [],
      accountholder_ids:[],
      examDay: {
        name: '',
        type: '',
        score_id: '',
        is_active: true,
      }
    }
  },
  mounted() {
    this.getCurrentUser();
  },
  computed: {
    sendToAllNotification() {
      let count = 0;
      this.notifications.forEach((notification) => {
        if(!notification.notification_sent) {
          count += 1;
        }
      });

      return this.notifications.length === count;

    }
  },
  methods:{
    closeDialog() {
      this.dialog = false;
      this.errors = [];
      this.resetForm();
    },

    showDialog(examDayInfo) {
      this.dialog = true;
      this.isLoading = true;
      this.errors = [];
      this.accountholder_ids=[];
      examDay
          .show(examDayInfo.id)
          .then(response=> {
            this.examDay = response.data.examDay;
            this.isLoading = false;
          });
      this.getExamDayAccountHolderNotifications(examDayInfo.id)
    },

    getExamDayAccountHolderNotifications(examDayId)
    {
      examDay
          .getAccountHolderNotifications(examDayId)
          .then(response => {
            this.notifications = response.data.notifications;
          })
          .catch((err) => {

          });
    },

    sendNotification(sendType)
    {

      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            let data={
              'send_type':sendType,
              'accountholder_ids':this.accountholder_ids,
            };
            this.loading = true;
            examDay
                .sendNotificationToAccountHolders(this.examDay.id,data)
                .then((response) => {
                  if(response.data.status == "OK") {
                    this.$snotify.success("Schedule Notification Sent ");
                    this.$emit('refresh');
                    this.closeDialog();
                  }
                })
                .catch((err) => {
                  this.$snotify.error("Oops something went wrong");
                  this.closeDialog();

                }).finally(() => {
                  this.loading = false;
            });
          }
        },
      });
    },

    resendNotificationToAccountHolder(accountHolderId)
    {
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            this.isDialog = true;
            examDay
                .sendNotificationToAccountHolder(this.examDay.id,accountHolderId)
                .then((response) => {
                  if(response.data.status == "OK") {
                    this.$snotify.success("Notification sent ");
                    this.$emit('refresh');
                    this.closeDialog();
                  }
                })
                .catch((err) => {
                  this.$snotify.error("Oops something went wrong");
                  this.closeDialog();

                }).finally(() => {
                    this.isDialog = false;
                });
          }
        },
      });
    },

    resetForm() {
      this.errors = [];
      this.$v.$reset();
      this.location = {
        name: '',
        type: '',
        score_id: '',
        is_active: true,
      };
      this.accountholder_ids=[];
    },
  },
}
</script>