import Apiservice from '@/common/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;

export default class VenueService
{
    #api = null;

    constructor() {
        this.#api = API_URL + 'admin/venue';
    }
    paginate(data={},index = null) {
        let url = `${this.#api}`;
        if (index != null)
            url = `${url}?page=${index}`;
        let param ={
            params: data
        }
        return apiService.query(url,param);
    }
    create(data) {
        let url = `${this.#api}`;
        return apiService.post(url, data);
    }

    update(id, data) {
        let url = `${this.#api}/${id}/update`
        return apiService.post(url, data)
    }
    show(id) {
        let url = `${this.#api}/${id}`
        return apiService.get(url)
    }
    delete(id) {
        let url = `${this.#api}/${id}`
        return apiService.delete(url);
    }
    deleteImage(data={},id){
        let url = `${this.#api}/${id}/delete-image`;
        let param ={
            params: data
        }
        return apiService.query(url,param);
    }
    all(data={}){

        let url = `${this.#api}/get/all`;
        let param ={
            params: data
        }
        return apiService.query(url,param)
    }

    checkIfVenueNotified(data={}){
        let url = `${this.#api}/check/venue-notified`;
        let param ={
            params: data
        }
        return apiService.query(url,param)
    }

    getVenueRequests(data={}, index=null){
        let url = `${this.#api}-request`;
        if (index != null)
            url = `${url}?page=${index}`;
        let param ={
            params: data
        }
        return apiService.query(url,param);
    }

    approveRequest(venueId, data={}){
        let url = `${this.#api}-request/${venueId}/approve`;
        return apiService.post(url, data);
    }

    rejectRequest(venueId, data={}){
        let url = `${this.#api}-request/${venueId}/reject`;
        return apiService.post(url, data);
    }
    verifyVenueCode(data={}){
        let url = `${this.#api}/verify/venue_code`;

        let param ={
            params: data
        }
        return apiService.query(url,param);
    }
    getByLocation(locationId) {
        let url = `${this.#api}/${locationId}/get/by/location`
        return apiService.get(url)
    }
    setAsCentral(venueId,data) {
        let url = `${this.#api}/${venueId}/set-as-central`
        return apiService.post(url,data)
    }
    checkIfPrimaryVenueContactExist(venueId){
        let url = `${this.#api}/${venueId}/check/primary-venue-contact`
        return apiService.get(url)
    }
    getPrivateVenuesByScore(scoreId){
        let url = `${this.#api}/score/${scoreId}/get-private`
        return apiService.get(url)
    }
    exportToCsv(data){
        let url = `${this.#api}/export/csv?info=${data.info}&is_active=${data.is_active}&score_id=${data.score_id}&location_id=${data.location_id}&type=${data.type}`;
        window.open(url, "_blank")
    }
    exportToExcel(data){
        let url = `${this.#api}/export/excel?info=${data.info}&is_active=${data.is_active}&score_id=${data.score_id}&location_id=${data.location_id}&type=${data.type}`;
        window.open(url, "_blank")
    }
}