<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Schedules</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ Schedules
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">
                  <v-btn @click="createOrExamDay()" class="btn btn-primary mr-2" style="color: #fff">
                    <v-icon small elevation="2" outlined>fas fa-plus</v-icon>&nbsp;
                    Add schedule
                  </v-btn>
                  <b-dropdown size="sm" variant="link" toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon" no-caret right no-flip>
                    <template v-slot:button-content>
                      <i class="ki ki-bold-more-hor"></i>
                    </template>
                    <!--begin::Navigation-->
                    <div class="navi navi-hover min-w-md-250px">
                      <b-dropdown-text tag="div" class="navi-item">
                        <a @click="addFilter()"  class="navi-link">
                                  <span class="navi-icon">
                                      <i class="fa fa-filter"></i>
                                  </span>
                          <span class="navi-text">Filters</span>
                        </a>
                      </b-dropdown-text>
                      <b-dropdown-text tag="div" class="navi-item">
                        <a @click="exportToExcel()"  class="navi-link">
                                  <span class="navi-icon">
                                      <i class="fa fa-file-excel"></i>
                                  </span>
                          <span class="navi-text">Export (excel)</span>
                        </a>
                      </b-dropdown-text>
                      <b-dropdown-text tag="div" class="navi-item">
                        <a @click="exportToCsv()"  class="navi-link">
                                  <span class="navi-icon">
                                      <i class="fa fa-file-csv"></i>
                                  </span>
                          <span class="navi-text">Export (CSV)</span>
                        </a>
                      </b-dropdown-text>

                      <b-dropdown-text tag="div" class="navi-item">
                        <a @click="showSelectField()"  class="navi-link">
                                  <span class="navi-icon">
                                      <i class="fa fa-database"></i>
                                  </span>
                          <span class="navi-text">Export fields</span>
                        </a>
                      </b-dropdown-text>
                    </div>
                    <!--end::Navigation-->
                  </b-dropdown>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            
            <div v-if="customFilters.length > 0" class="mb-7">
              <a href="javascript:;" @click="showSavedSearch = !showSavedSearch" class="btn btn-primary text-white"> <i class="fa fa-info"></i> Saved search </a>
              <div class="mt-2" v-if="showSavedSearch">
                <span v-for="(item, idx) in customFilters" class="d-block">
                  <i class="fa fa-clock-o"></i> {{item.created_at_formatted}}, 
                  <strong>Type: </strong> {{item.is_private ? 'Personal' : 'All'}}, 
                  <strong>Name : </strong> <a href="javascript:;" @click="openSavedSearch(item.id)" class="font-weight-bold"> {{item.name}} </a>
                  <a href="javascript:;" @click="deleteCustomFilter(item.id)" class="ml-3"> <i class="fa fa-trash blue--text"></i> </a>
                </span>
              </div>
            </div>

            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <div class="row">

                <v-col cols="12" sm="6" md="2" v-if="currentUser.access_type!='score'">
                  <v-autocomplete
                      :items="scores"
                      v-model="search.score_id"
                      label="SCORE"
                      item-text="name"
                      item-value="id"
                      outlined
                      dense clearable
                      v-on:keyup.enter="searchExamDays()"
                      @change="getScoreLocations()"
                  >
                    <template v-slot:no-data>
                      <div class="no-data-auto-complete text-center">No data available </div>
                    </template>
                  </v-autocomplete>
                </v-col>

                <v-col cols="12" sm="6" md="2" >
                  <v-autocomplete @change="changeEnrolmentYear"
                                  :items="enrolment_years"
                                  v-model="search.enrolment_year_id"
                                  label="Year"
                                  item-text="year"
                                  item-value="id"
                                  outlined
                                  dense clearable
                                  v-on:keyup.enter="searchExamDays()"
                  >
                    <template v-slot:no-data>
                      <div class="no-data-auto-complete text-center">No data available </div>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                      label="Schedule title"
                      v-model="search.info"
                      outlined
                      dense
                      clearable
                      v-on:keyup.enter="searchExamDays()"
                  ></v-text-field>
                </v-col>

                <v-col class="d-flex" cols="12" sm="6" md="2">
                  <v-autocomplete
                      :items="search_categories"
                      v-model="search.category_id"
                      label="Category"
                      item-text="name"
                      item-value="id"
                      clearable
                      v-on:keyup.enter="searchExamDays()"
                      @input="search.category_id = $event !== null ? $event : ''"
                      outlined
                      dense
                      @change="getAllInstruments"
                  >
                    <template v-slot:no-data>
                      <div class="no-data-auto-complete text-center">No data available </div>
                    </template>
                  </v-autocomplete>
                </v-col>

                <v-col class="d-flex" cols="12" sm="6" md="2">
                  <v-autocomplete
                      :items="search_instruments"
                      v-model="search.instrument_id"
                      label="Family"
                      item-text="name"
                      item-value="id"
                      outlined
                      clearable
                      v-on:keyup.enter="searchExamDays()"
                      @input="search.instrument_id = $event !== null ? $event : ''"
                      dense
                      @change="getAllSyllabuses"
                      :loading="isInstrumentLoading"
                  >
                    <template v-slot:no-data>
                      <div class="no-data-auto-complete text-center">No data available </div>
                    </template>
                  </v-autocomplete>
                </v-col>

                <v-col class="d-flex" cols="12" sm="6" md="2">
                  <v-autocomplete
                      :items="search_syllabuses"
                      v-model="search.syllabus_id"
                      label="Syllabus"
                      item-text="name"
                      item-value="id"
                      outlined
                      clearable
                      @input="search.syllabus_id = $event !== null ? $event : ''"
                      dense
                      v-on:keyup.enter="searchExamDays()"
                      :loading="isSyllabusLoading"
                  >
                    <template v-slot:no-data>
                      <div class="no-data-auto-complete text-center">No data available </div>
                    </template>
                  </v-autocomplete>
                </v-col>

                <v-col class="d-flex" cols="12" sm="6" md="2">
                  <v-autocomplete
                      label="Scheduling method"
                      outlined :items="types"
                      dense
                      item-value="value"
                      item-text="name"
                      v-model="search.type"
                      clearable
                      v-on:keyup.enter="searchExamDays()"
                  >
                    <template v-slot:no-data>
                      <div class="no-data-auto-complete text-center">No data available </div>
                    </template>
                  </v-autocomplete>
                </v-col>

                <v-col cols="12" sm="6" :md="currentUser.access_type != 'score' ? 2 : 4">
                  <v-autocomplete
                      :items="exam_sessions"
                      v-model="search.exam_session_id"
                      label="Session"
                      item-text="name"
                      item-value="id"
                      outlined
                      :loading="isSessionLoading"
                      dense clearable
                      :search-input.sync="sessionSearch"
                      v-on:keyup.enter="searchExamDays()"
                  >
                    <template v-slot:no-data>
                      <div class="no-data-auto-complete text-center">No data available </div>
                    </template>
                  </v-autocomplete>
                </v-col>

                <v-col cols="12" sm="6" md="2">
                  <v-autocomplete
                      :items="locations"
                      v-model="search.location_id"
                      label="Location"
                      item-text="name"
                      item-value="id"
                      outlined
                      dense clearable
                      v-on:keyup.enter="searchExamDays()"
                  >
                    <template v-slot:no-data>
                      <div class="no-data-auto-complete text-center">No data available </div>
                    </template>
                  </v-autocomplete>
                </v-col>



                <v-col cols="12" sm="6" md="2">
                  <v-menu
                      ref="menuDate"
                      v-model="menuStartDate"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          v-model="search.start_date"
                          label="View from date"
                          readonly
                          outlined
                          dense
                          clearable
                          v-bind="attrs"
                          v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="search.start_date"
                        outlined
                        dense
                        no-title
                        @input="menuStartDate = false"
                    >
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                  <v-menu
                      ref="menuDate"
                      v-model="menuEndDate"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          v-model="search.end_date"
                          label="View up to date"
                          readonly
                          outlined
                          dense
                          clearable
                          v-bind="attrs"
                          v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="search.end_date"
                        outlined
                        dense
                        no-title
                        @input="menuEndDate = false"
                    >
                    </v-date-picker>
                  </v-menu>
                </v-col>

                <v-col class="d-flex" cols="12" sm="6" md="2">
                  <v-select
                      :items="yes_no_status"
                      v-model="search.has_empty_slots"
                      label="Empty slots"
                      placeholder="Empty slots"
                      item-text="name"
                      item-value="value"
                      outlined
                      clearable
                      dense
                  >
                    <template v-slot:no-data>
                      <div class="no-data-auto-complete text-center">No data available </div>
                    </template>
                  </v-select>
                </v-col>

                <v-col class="d-flex" cols="12" sm="6" md="2">
                  <v-autocomplete
                      :items="notifications"
                      v-model="search.notification_status"
                      label="Notifications"
                      item-text="name"
                      clearable
                      item-value="value"
                      outlined
                      dense
                  >
                    <template v-slot:no-data>
                      <div class="no-data-auto-complete text-center">No data available </div>
                    </template>
                  </v-autocomplete>
                </v-col>

                <v-col class="d-flex" cols="12" sm="6" md="2">
                  <v-autocomplete
                      :items="report_statuses"
                      v-model="search.report_status"
                      label="Report status"
                      item-text="name"
                      clearable
                      item-value="value"
                      outlined
                      dense
                  >
                    <template v-slot:no-data>
                      <div class="no-data-auto-complete text-center">No data available </div>
                    </template>
                  </v-autocomplete>
                </v-col>


                <v-col class="d-flex" cols="12" sm="6" md="2">
                  <v-autocomplete
                      :items="status"
                      v-model="search.status"
                      label="Status"
                      item-text="name"
                      clearable
                      item-value="value"
                      outlined
                      dense
                  >
                    <template v-slot:no-data>
                      <div class="no-data-auto-complete text-center">No data available </div>
                    </template>
                  </v-autocomplete>
                </v-col>

                <v-col class="d-flex" cols="12" sm="6" md="2">
                  <v-autocomplete
                      :items="examiners"
                      v-model="search.examiner_id"
                      label="Examiner"
                      item-text="full_name"
                      item-value="id"
                      clearable
                      :search-input.sync="examinerSearch"
                      v-on:keyup.enter="searchExamDays()"
                      outlined
                      dense
                      cache-items
                  >
                    <template v-slot:no-data>
                      <div class="no-data-auto-complete text-center">No data available </div>
                    </template>
                  </v-autocomplete>
                </v-col>


                <v-col class="d-flex" cols="12" sm="6" md="2">
                  <v-autocomplete
                      :items="supervisors"
                      v-model="search.supervisor_id"
                      label="Supervisor"
                      item-text="full_name"
                      clearable
                      item-value="id"
                      :search-input.sync="supervisorSearch"
                      v-on:keyup.enter="searchExamDays()"
                      outlined
                      dense
                      cache-items
                  >
                    <template v-slot:no-data>
                      <div class="no-data-auto-complete text-center">No data available </div>
                    </template>
                  </v-autocomplete>
                </v-col>

                <v-col cols="12" sm="6" md="2">
                  <v-select
                    outlined
                    dense
                    v-model="search.is_private"
                    :items="sessionPrivacyTypes"
                    item-text="name"
                    item-value="value"
                    clearable
                    v-on:keyup.enter="searchExamDays()"
                    label="Private session"
                  ></v-select>
                </v-col>

                <v-col cols="12" sm="6" md="10" class="text-right">
                  <v-btn
                      @click="searchExamDays"
                      class="btn btn-primary btn-search"
                      :loading="loading"
                  >
                    <v-icon small  elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>

                <v-col v-if="this.filters[0].search_fields != ''" cols="12" md="2">
                  <v-btn
                      @click="saveLink('private')"
                      class="mt-1 btn btn-block btn-primary"
                      style="color: #fff;"
                  >
                    <v-icon small  elevation="2" outlined>fas fa-save</v-icon>&nbsp;
                    Save for me
                  </v-btn>
                </v-col>

                <v-col v-if="this.filters[0].search_fields != ''" cols="12" md="2">
                  <v-btn
                      @click="saveLink('all')"
                      class="mt-1 btn btn-block btn-primary"
                      style="color: #fff;"
                  >
                    <v-icon small  elevation="2" outlined>fas fa-save</v-icon>&nbsp;
                    Save for all
                  </v-btn>
                </v-col>

              </div>
            </div>

            <div class="row mb-2" v-if="search.search_fields.length != 0">
              <v-col cols="12" md="12">
                <div class="subtitle font-weight-bold">
                  Filter applied
                </div>
              </v-col>
              <v-col cols="12" md="10">
                <div class="w-full">
                      <v-btn class="mr-2 mb-2" color="primary"
                          v-for="(item, index) in search.search_fields"
                          v-if="item"
                          :key="index"
                      >
                        {{ formatFilterName(item)}}
                      </v-btn>
                </div>
              </v-col>
              <v-col cols="12" md="2" class="text-right">
                <v-btn
                    @click="resetAppliedFilter"
                    color="black"
                    outlined
                >Reset filter
                </v-btn>
              </v-col>
            </div>

            <v-row>
              <v-col cols="2">
                <v-autocomplete
                  outlined
                  :items="sortByActions"
                  label="Sort by schedule date"
                  item-text="name"
                  item-value="value"
                  v-model="search.sort_by_date"
                  @change="sortExamDays"
                  dense
                >
                </v-autocomplete>  
              </v-col>
              
              <v-col cols="7"></v-col>
              
              <v-col cols="3">
                 <div class="d-flex justify-space-between">
                   <v-autocomplete
                      outlined
                      :items="available_actions"
                      label="Select action"
                      item-text="text"
                      item-value="value"
                      v-model="bulk_action"
                      dense
                    >
                    </v-autocomplete>
                    
                    <v-btn class="btn btn-primary ml-4" style="color: #fff" @click="performBulkAction" :loading="bulkActionLoading">
                      Go
                      <v-icon small elevation="2" outlined class="ml-2">fas fa-arrow-right</v-icon>&nbsp;
                    </v-btn>
                 </div>
               </v-col>
            </v-row>
            
            <div class="table-responsive" style="table-layout: fixed">
              <v-skeleton-loader
                type="table-thead"
                v-if="loading"
              >
              </v-skeleton-loader>

              <v-skeleton-loader
                v-if="loading"
                type="table-row-divider@25"
              >
              </v-skeleton-loader>

              <table class="table" v-if="!loading">
                <thead>
                <tr class="px-3">
                  <th class="px-3" style="max-width: 75px !important; white-space: pre-wrap;">
                    <v-checkbox
                        @click="checkAllExamDays"
                        v-if="exam_days.length > 0"
                        v-model="checkAllExamDaysFlag"
                    ></v-checkbox>
                  </th>
                  <th class="px-3" style="max-width: 240px !important; white-space: pre-wrap;">Schedule</th>
                  <th class="px-3" style="max-width: 200px !important; white-space: pre-wrap;">Date</th>
                  <th class="px-3" style="max-width: 160px !important; white-space: pre-wrap;">Location</th>
                  <th class="px-3" style="max-width: 160px !important; white-space: pre-wrap;">Venue</th>
                  <th class="px-3" style="max-width: 160px !important; white-space: pre-wrap;">Team</th>
                  <th class="px-3" style="max-width: 260px !important; white-space: pre-wrap;">Exam session</th>
                  <th style="max-width: 90px !important; white-space: pre-wrap;">Enrolment year</th>
                  <th class="px-3" style="max-width: 210px !important; white-space: pre-wrap;" v-if="currentUser.access_type!='score'">SCORE</th>
                  <th class="pr-3 text-center">Options</th>
                </tr>
                </thead>
                <tbody>
                <template>
                    <div class="schedule-data" style="width: 100%; display: contents;" v-if="exam_days.length > 0" v-for="(item, index) in exam_days" :key="index" :style="item.is_administrated_by_federal?'background-color:#e3e3e3 !important;':''">
                        <tr>
                            <td class="px-3" style="width: 75px !important; white-space: pre-wrap;">
                                <v-checkbox
                                        v-model="exam_day_ids"
                                        :value="item.id"
                                ></v-checkbox>
                            </td>
                            <td class="px-3" style="max-width: 240px !important; white-space: pre-wrap;">
                                <p class="px-2 font-weight-bolder mb-1">
                                    <a @click="viewCandidate(item.id)" v-if="item.is_confirmed"> {{item.name }}</a>
                                    <a @click="manageSchedule(item.id)" v-else> {{item.name }}</a>
                                    
                                    <a href="#" v-if="item.chief_examiner_missing" 
                                      class="ml-3"
                                      title="Chief examiner is missing">
                                      <i class="fas fa-circle-exclamation" style="color: rgb(240, 54, 30); font-size: 17px;"></i>
                                    </a>
                                  </p><br/>
                                <span class="badge badge-warning"  v-if="item.is_administrated_by_federal"> Administrated by {{item.is_administrated_by_federal? 'Federal': 'Score'}}</span>
                                <span class="badge badge-info" style="background: rosybrown">  {{item.type_text ? item.type_text : 'NA'}}</span>
                                <span class="badge badge-info" v-if="item.notes_count > 0"><i class="fa fa-comment font-white"></i>  ({{item.notes_count}})</span>
                                <span class="badge badge-primary" v-if="item.candidates_count > 0"  title="Total candidates">{{item.candidates_count}}</span>
<!--                                <span class="badge badge-primary" v-if="item.has_empty_slots"><i class="fa fa-list font-white"></i> Empty slots</span>-->
                            </td>
                            <td class="px-2" style="max-width: 200px !important; white-space: pre-wrap;">
                                {{item.exam_date_formatted}}<br/>
                                {{item.exam_start_time_formatted}} - {{item.exam_end_time_formatted}}<br/>
                                <b v-if="item.timesheet_hours!='' && item.timesheet_minutes!=''">Timesheet:</b> <span v-if="item.timesheet_hours!=''">{{item.timesheet_hours}}hr </span> <span v-if="item.timesheet_minutes!=''">{{item.timesheet_minutes}}mins </span>

                            </td>
                            <td class="px-2" style="max-width: 160px !important; white-space: pre-wrap;" alt="Location">
                                {{item.location_name}}
                            </td>
                            <td class="px-2" style="max-width: 160px !important; white-space: pre-wrap;">
                                {{item.venue_name}}<br/>
                                {{item.room_name}}
                                <span v-if="item.is_private_venue" class="badge badge-success" title="Private venue">P</span>
                            </td>
                            <td style="max-width: 160px !important; white-space: pre-wrap;">
                                <div v-html="item.examiners_name_by_hierarchy"></div>
                                <div v-html="item.supervisors_name_by_hierarchy"></div>
                            </td>
                            <td class="px-2" style="max-width: 260px !important; white-space: pre-wrap;">
                                {{ item.exam_session_name ? item.exam_session_name : '-' }}
                            </td>
                            <td class="text-left">
                                {{ item.enrolment_year ?  item.enrolment_year : '--'}}
                            </td>
                            <td class="text-left" v-if="currentUser.access_type!='score'" style="max-width: 260px !important; white-space: pre-wrap;">
                                {{ item.score_short_name ?  item.score_short_name : '-'}}
                            </td>

                            <td class="pr-0 text-center">
                                <template>
                                    <b-dropdown
                                            size="sm"
                                            variant="link"
                                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                                            no-caret
                                            right
                                            no-flip
                                            v-if="!item.is_administrated_by_federal && (currentUser.access_type=='score' || currentUser.access_type=='federal')"
                                    >
                                        <template v-slot:button-content>
                                            <i class="ki ki-bold-more-hor"></i>
                                        </template>
                                        <!--begin::Navigation-->
                                        <div class="navi navi-hover min-w-md-250px">
                                            <b-dropdown-text tag="div" class="navi-item">
                                                <a @click="manageSchedule(item.id)" class="navi-link">
                                <span class="navi-icon">
                                  <i class="fas fa-clock"></i>
                                </span>
                                                    <span class="navi-text">Manage scheduler</span>
                                                </a>
                                            </b-dropdown-text>
                                            <b-dropdown-text tag="div" class="navi-item">
                                                <a @click="createOrExamDay(item.id)" class="navi-link">
                                <span class="navi-icon">
                                  <i class="fas fa-edit"></i>
                                </span>
                                                    <span class="navi-text">Edit schedule details</span>
                                                </a>
                                            </b-dropdown-text>

                                            <b-dropdown-text tag="div" class="navi-item">
                                                <a @click="viewCandidate(item.id)" class="navi-link">
                                <span class="navi-icon">
                                  <i class="fas fa-eye"></i>
                                </span>
                                                    <span class="navi-text">View candidates</span>
                                                </a>
                                            </b-dropdown-text>

                                            <b-dropdown-text tag="div" class="navi-item" v-if="item.candidates_count>0">
                                                <a @click="viewExaminerTimeTablePdf(item.id)" class="navi-link">
                                <span class="navi-icon">
                                   <i class="fas fas fa-file-pdf"></i>
                                </span>
                                                    <span class="navi-text">View timetable (examiner)</span>
                                                </a>
                                            </b-dropdown-text>

                                            <b-dropdown-text tag="div" class="navi-item" v-if="item.candidates_count>0">
                                                <a @click="viewSupervisorTimeTablePdf(item.id)" class="navi-link">
                                <span class="navi-icon">
                                   <i class="fas fas fa-file-pdf"></i>
                                </span>
                                                    <span class="navi-text">View timetable (supervisor)</span>
                                                </a>
                                            </b-dropdown-text>

                                            <b-dropdown-text tag="div" class="navi-item" v-if="item.is_confirmed">
                                                <a @click="sendAccountHolderNotification(item)" class="navi-link">
                                <span class="navi-icon">
                                  <i class="fas fas fa-bell"></i>
                                </span>
                                                    <span class="navi-text">Notify enroller</span>
                                                </a>
                                            </b-dropdown-text>
                                            <b-dropdown-text tag="div" class="navi-item" v-if="item.is_confirmed">
                                                <a @click="sendExaminerNotification(item)" class="navi-link">
                                <span class="navi-icon">
                                   <i class="fas fa-bell"></i>
                                </span>
                                                    <span class="navi-text">Notify examiner</span>
                                                </a>
                                            </b-dropdown-text>
                                            <b-dropdown-text tag="div" class="navi-item" v-if="item.is_confirmed">
                                                <a @click="sendSupervisorNotification(item)" class="navi-link">
                                <span class="navi-icon">
                                  <i class="fas fa-bell"></i>
                                </span>
                                                    <span class="navi-text">Notify supervisor</span>
                                                </a>
                                            </b-dropdown-text>
                                            <b-dropdown-text tag="div" class="navi-item" v-if="item.is_confirmed && item.owner_id && item.is_private_venue">
                                                <a @click="sendVenueNotification(item)" class="navi-link">
                                <span class="navi-icon">
                                  <i class="fas fa-bell"></i>
                                </span>
                                                    <span class="navi-text">Notify venue</span>
                                                </a>
                                            </b-dropdown-text>

                                            <!-- <b-dropdown-text tag="div" class="navi-item" v-if="item.is_confirmed && !item.is_complete">
                                              <a @click="markAsComplete(item.id)" class="navi-link">
                                                <span class="navi-icon">
                                                  <i class="fas fas fa-check-circle"></i>
                                                </span>
                                                <span class="navi-text">Mark as complete </span>
                                              </a>
                                            </b-dropdown-text> -->

                                            <b-dropdown-text tag="div" class="navi-item" v-if="item.can_mark_report_status">
                                                <a @click="markReportStatus(item)" class="navi-link">
                                <span class="navi-icon">
                                  <i class="fas" :class="item.is_reports_received ? 'fa-times-circle' : 'fa-check-circle'"></i>
                                </span>
                                                    <span class="navi-text"> {{ item.is_reports_received ? 'Mark as report not received' : 'Mark as report received' }}</span>
                                                </a>
                                            </b-dropdown-text>

                                            <b-dropdown-text tag="div" class="navi-item">
                                                <a  @click="deleteExamDay(item.id, item.has_candidate)" class="navi-link">
                                <span class="navi-icon">
                                  <i class="fas fa-trash"></i>
                                </span>
                                                    <span class="navi-text">Delete</span>
                                                </a>
                                            </b-dropdown-text>
                                        </div>
                                        <!--end::Navigation-->
                                    </b-dropdown>

                                    <b-dropdown
                                            size="sm"
                                            variant="link"
                                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                                            no-caret
                                            right
                                            no-flip
                                            v-if="item.is_administrated_by_federal && currentUser.access_type=='federal'"
                                    >
                                        <template v-slot:button-content>
                                            <i class="ki ki-bold-more-hor"></i>
                                        </template>
                                        <!--begin::Navigation-->
                                        <div class="navi navi-hover min-w-md-250px">
                                            <b-dropdown-text tag="div" class="navi-item">
                                                <a @click="manageSchedule(item.id)" class="navi-link">
                                <span class="navi-icon">
                                  <i class="fas fa-clock"></i>
                                </span>
                                                    <span class="navi-text">Manage scheduler</span>
                                                </a>
                                            </b-dropdown-text>
                                            <b-dropdown-text tag="div" class="navi-item">
                                                <a @click="createOrExamDay(item.id)" class="navi-link">
                                <span class="navi-icon">
                                  <i class="fas fa-edit"></i>
                                </span>
                                                    <span class="navi-text">Edit schedule details</span>
                                                </a>
                                            </b-dropdown-text>

                                            <b-dropdown-text tag="div" class="navi-item">
                                                <a @click="viewCandidate(item.id)" class="navi-link">
                                <span class="navi-icon">
                                  <i class="fas fa-eye"></i>
                                </span>
                                                    <span class="navi-text">View candidates</span>
                                                </a>
                                            </b-dropdown-text>

                                            <b-dropdown-text tag="div" class="navi-item" v-if="item.is_confirmed">
                                                <a @click="viewExaminerTimeTablePdf(item.id)" class="navi-link">
                                <span class="navi-icon">
                                   <i class="fas fas fa-file-pdf"></i>
                                </span>
                                                    <span class="navi-text">View timetable (examiner)</span>
                                                </a>
                                            </b-dropdown-text>

                                            <b-dropdown-text tag="div" class="navi-item" v-if="item.is_confirmed">
                                                <a @click="viewSupervisorTimeTablePdf(item.id)" class="navi-link">
                                <span class="navi-icon">
                                   <i class="fas fas fa-file-pdf"></i>
                                </span>
                                                    <span class="navi-text">View timetable (supervisor)</span>
                                                </a>
                                            </b-dropdown-text>

                                            <b-dropdown-text tag="div" class="navi-item" v-if="item.is_confirmed">
                                                <a @click="sendAccountHolderNotification(item)" class="navi-link">
                                <span class="navi-icon">
                                  <i class="fas fas fa-bell"></i>
                                </span>
                                                    <span class="navi-text">Notify enroller</span>
                                                </a>
                                            </b-dropdown-text>
                                            <b-dropdown-text tag="div" class="navi-item" v-if="item.is_confirmed">
                                                <a @click="sendExaminerNotification(item)" class="navi-link">
                                <span class="navi-icon">
                                   <i class="fas fa-bell"></i>
                                </span>
                                                    <span class="navi-text">Notify examiner</span>
                                                </a>
                                            </b-dropdown-text>
                                            <b-dropdown-text tag="div" class="navi-item" v-if="item.is_confirmed">
                                                <a @click="sendSupervisorNotification(item)" class="navi-link">
                                <span class="navi-icon">
                                  <i class="fas fa-bell"></i>
                                </span>
                                                    <span class="navi-text">Notify supervisor</span>
                                                </a>
                                            </b-dropdown-text>

                                            <b-dropdown-text tag="div" class="navi-item" v-if="item.is_confirmed && !item.is_complete">
                                                <a @click="markAsComplete(item.id)" class="navi-link">
                                <span class="navi-icon">
                                  <i class="fas fas fa-check-circle"></i>
                                </span>
                                                    <span class="navi-text">Mark as complete </span>
                                                </a>
                                            </b-dropdown-text>
                                            <b-dropdown-text tag="div" class="navi-item">
                                                <a  @click="deleteExamDay(item.id, item.has_candidate)" class="navi-link">
                                <span class="navi-icon">
                                  <i class="fas fa-trash"></i>
                                </span>
                                                    <span class="navi-text">Delete</span>
                                                </a>
                                            </b-dropdown-text>
                                        </div>
                                        <!--end::Navigation-->
                                    </b-dropdown>

                                    <b-dropdown
                                            size="sm"
                                            variant="link"
                                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                                            no-caret
                                            right
                                            no-flip
                                            v-if="item.is_administrated_by_federal && currentUser.access_type=='score'"
                                    >
                                        <template v-slot:button-content>
                                            <i class="ki ki-bold-more-hor"></i>
                                        </template>
                                        <!--begin::Navigation-->
                                        <div class="navi navi-hover min-w-md-250px">

                                            <b-dropdown-text tag="div" class="navi-item">
                                                <a @click="viewCandidate(item.id)" class="navi-link">
                                <span class="navi-icon">
                                  <i class="fas fa-eye"></i>
                                </span>
                                                    <span class="navi-text">View candidates</span>
                                                </a>
                                            </b-dropdown-text>

                                            <b-dropdown-text tag="div" class="navi-item" v-if="item.is_confirmed">
                                                <a @click="viewExaminerTimeTablePdf(item.id)" class="navi-link">
                                <span class="navi-icon">
                                   <i class="fas fas fa-file-pdf"></i>
                                </span>
                                                    <span class="navi-text">View timetable (examiner)</span>
                                                </a>
                                            </b-dropdown-text>

                                            <b-dropdown-text tag="div" class="navi-item" v-if="item.is_confirmed">
                                                <a @click="viewSupervisorTimeTablePdf(item.id)" class="navi-link">
                                <span class="navi-icon">
                                   <i class="fas fas fa-file-pdf"></i>
                                </span>
                                                    <span class="navi-text">View timetable (supervisor)</span>
                                                </a>
                                            </b-dropdown-text>

                                        </div>
                                        <!--end::Navigation-->
                                    </b-dropdown>
                                </template>
                            </td>
                        </tr>
                        <div class="schedule-data-badge" style="width: 100%; display: contents;">
                            <td style="border-top:none !important;">&nbsp;</td>
                            <td colspan="10" style="border-top:none !important;text-align: left;">
                                <div v-if="item.is_confirmed" class="badge badge-success" style="max-width: 100px !important; white-space: pre-wrap;display: inline;">Confirmed</div>
                                <div v-else class="badge badge-warning" style="max-width: 100px !important; white-space: pre-wrap;display: inline;">Not confirmed</div>
                                <div v-if="!item.is_active" class="badge badge-danger" style="max-width: 100px !important; white-space: pre-wrap;display: inline;">Inactive</div>
                                <div class="badge badge-info mt-2" style="max-width: 100px !important; white-space: pre-wrap;display: inline;" v-if="item.is_account_holder_notified && item.is_confirmed"> Enroller notified</div>
                                <div class="badge badge-warning mt-2" style="max-width: 100px !important; white-space: pre-wrap;display: inline;" v-if="!item.is_account_holder_notified  && item.is_confirmed"> Enroller not notified</div>
                                <div class="badge badge-info mt-2" style="max-width: 100px !important; white-space: pre-wrap;display: inline;" v-if="item.is_examiner_notified && item.is_confirmed"> Examiners notified</div>
                                <div class="badge badge-warning mt-2" style="max-width: 100px !important; white-space: pre-wrap;display: inline;" v-if="!item.is_examiner_notified  && item.is_confirmed"> Examiners not notified</div>
                                <div class="badge badge-info mt-2" style="max-width: 100px !important; white-space: pre-wrap;display: inline;" v-if="item.is_supervisor_notified && item.is_confirmed"> Supervisors notified</div>
                                <div class="badge badge-warning mt-2" style="max-width: 100px !important; white-space: pre-wrap;display: inline;" v-if="!item.is_supervisor_notified  && item.is_confirmed"> Supervisors not notified</div>
                                <div class="badge badge-warning mt-2" style="max-width: 100px !important; white-space: pre-wrap;display: inline;" v-if="item.is_private_venue  && item.is_confirmed && !item.is_private_venue_notified && item.owner_id"> Venue not notified</div>
                                <div class="badge badge-info mt-2" style="max-width: 100px !important; white-space: pre-wrap;display: inline;" v-if="item.is_timetable_printed && item.is_confirmed"> Timetable printed</div>
                                <div class="badge badge-warning mt-2" style="max-width: 100px !important; white-space: pre-wrap;display: inline;" v-if="!item.is_timetable_printed  && item.is_confirmed"> Timetable not printed</div>
                                <div class="badge badge-info mt-2" style="max-width: 100px !important; white-space: pre-wrap;display: inline;" v-if="item.is_result_added && item.is_confirmed && item.is_complete"> Results added</div>
                                <div class="badge badge-info mt-2" style="max-width: 100px !important; white-space: pre-wrap;display: inline;" v-if="item.is_result_dispatched && item.is_confirmed"> Results printed</div>
                                <div class="badge badge-info mt-2" style="max-width: 100px !important; white-space: pre-wrap;display: inline;" v-if="item.is_user_session"> Enroler's schedule</div>
                                <div class="badge badge-success mt-2" style="max-width: 100px !important; white-space: pre-wrap;display: inline;" v-if="item.is_user_session  && item.owner_day_confirmed">Confirmed by enroler</div>
                                <div class="badge badge-success mt-2" style="max-width: 100px !important; white-space: pre-wrap;display: inline;" 
                                  v-if="item.is_private_venue  && item.is_confirmed && item.is_private_venue_notified && item.owner_id">Venue notified
                                </div> 

                                <div class="mt-2"></div>
                              <div class="badge badge-primary mt-2" style="white-space: pre-wrap;display: inline-block;background: purple;" v-if="item.has_empty_slots">
                                <i class="fa fa-list font-white"></i> Empty slots ({{ item.total_empty_slot_durations }} mins)
                              </div>
                                <span 
                                  style="background: purple !important;"
                                  class="badge badge-secondary text-white"
                                  v-for="(emptySlotDuration) in item.empty_slot_durations"
                                  v-if="item.empty_slot_durations && item.empty_slot_durations.length > 0" 
                                >
                                  {{ emptySlotDuration }} minutes
                                </span>
                            </td>
                        </div>
                    </div>

                  <tr v-if="exam_days.length == 0">
                    <td colspan="11" class="text-center">
                      <strong>No schedules available to view</strong>
                    </td>
                  </tr>
                </template>
                </tbody>
              </table>
              <b-pagination
                  v-if="exam_days.length > 0"
                  class="pull-right mt-7"
                  @input="getAllExamDays"
                  v-model="page"
                  :total-rows="total"
                  :disabled="loading"
                  :per-page="perPage"
                  first-number
                  last-number
              ></b-pagination>
            </div>
          </div>

          <!-- filters popup -->
          <v-dialog
              scrollable
              v-model="searchFieldDialog"
              width="800px"
          >
            <v-card>
              <v-card-title>
                Search filters
              </v-card-title>
              <v-card-text>
                <v-row class="mt-2" v-for="(row, index) in filters" :key="index">
                  <v-col cols="12" md="3">
                    <v-select
                        v-model="row.search_fields"
                        :items="examDayFiltersArray"
                        item-text="text"
                        item-value="value"
                        label="Column"
                        outlined
                        dense
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-select
                        :items="conditionFilters"
                        v-model="row.search_conditions"
                        item-text="text"
                        item-value="value"
                        label="Condition"
                        outlined
                        dense
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-text-field
                        label="Value"
                        v-model="row.search_values"
                        outlined
                        dense
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-btn
                        v-if="index == filters.length-1"
                        color="blue"
                        class="text-white mr-2"
                        medium
                        @click="addFilterColumn()"
                    >
                      <i class="fa fa-plus font-white"></i>
                    </v-btn>
                    <v-btn
                        v-if="index || (!index && filters.length > 1)"
                        @click="removeFilterColumn(index)"
                        color="red"
                        class=" text-white"
                        medium
                    >
                      <i class="fa fa-times font-white"></i>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions class="d-flex justify-content-end">
                <v-btn
                    @click="closeSelectFieldDialog"
                    text
                    x-large
                >
                  Cancel
                </v-btn>
                <v-btn
                    @click="applyFilter"
                    x-large
                    dark
                >
                  Apply
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <!-- select fields to export popup -->
          <v-dialog
            scrollable
            v-model="selectFieldDialog"
            width="700px"
        >
          <v-card>
            <v-card-title>
              Select fields that should appear on the export
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col v-for="(item, index) in examDayFilters" cols="12" md="4">
                  <v-checkbox
                      :label="index"
                      :value="item"
                      v-model="selectedFields"
                  >
                  </v-checkbox>
                </v-col>
              </v-row>
            </v-card-text>

            <v-spacer></v-spacer>

            <v-card-actions class="d-flex justify-content-end">
              <v-btn
                  @click="selectFieldDialog = false;"
                  class="btn btn-primary text-white"
              >
                Select
              </v-btn>
              <v-btn
                  @click="closeExcelSelectFieldDialog"
                  class="btn btn-standard"
              >
                Cancel
              </v-btn>
            </v-card-actions>

          </v-card>
        </v-dialog>

        <!-- save search result popup -->
        <v-dialog
            scrollable
            v-model="showSaveDialog"
            width="800px"
        >
          <v-card>
            <v-card-title>
              Save search filter
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    class="mt-3"
                    outlined
                    dense
                    placeholder="Save search with name"
                    v-model="custom_filter.name"
                    :error="$v.custom_filter.name.$error"
                  >
                    <template v-slot:label>
                      Name <span class="text-danger">*</span>
                    </template>
                  </v-text-field>
                  <span class="text-danger" v-if="$v.custom_filter.name.$error">
                    This information is required
                  </span>
                </v-col>
              </v-row>
            </v-card-text>
            <v-spacer></v-spacer>

            <v-card-actions class="d-flex justify-content-end">
              <v-btn
                :loading="saveLinkLoading"
                class="btn btn-primary text-white"
                @click="saveType == 'private' ? saveLinkForMe() : saveLinkForAll()"
              >
                Save
              </v-btn>
              <v-btn
                class="btn btn-standard"
                @click="cancelSaveSearch"
              >
                Cancel
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- save search result popup ends -->
        
        </div>
      </div>
    </div>
    <account-holder-notification ref="account-holder-notification" @refresh="getAllExamDays"></account-holder-notification>
    <examiner-notification ref="examiner-notification" @refresh="getAllExamDays"></examiner-notification>
    <supervisor-notification ref="supervisor-notification" @refresh="getAllExamDays"></supervisor-notification>
    <venue-notification ref="venue-notification" @refresh="getAllExamDays"></venue-notification>
  </v-app>

</template>
<script>
import ExamSession from "@/services/practical-exam/exam-session/ExamSession";
import ExamDayService from "@/services/practical-exam/exam-day/ExamDayService";
import ScoreService from "@/services/score/score/ScoreService";
import CategoryService from "@/services/product/category/CategoryService";
import InstrumentService from "@/services/product/instrument/InstrumentService";
import SyllabusService from "@/services/product/syllabus/SyllabusService";
import EnrolmentYearService from "@/services/setting/enrolment-year/EnrolmentYearService";
import AdminUserService from "@/services/admin-user/AdminUserService";
import CustomFilterLinkService from "@/services/custom-filter-link/CustomFilterLinkService";
import LocationService from "@/services/practical-exam/location/LocationService";
import ExaminerService from "@/services/user/examiner/ExaminerService";
import SupervisorService from "@/services/user/supervisor/SupervisorService";
import {required} from "vuelidate/lib/validators";

const adminUser=new AdminUserService;
import AccountHolderNotification from "@/view/pages/view/practical-exam/exam-day/notification/AccountHolderNotification";
import ExaminerNotification from "@/view/pages/view/practical-exam/exam-day/notification/ExaminerNotification";
import SupervisorNotification from "@/view/pages/view/practical-exam/exam-day/notification/SupervisorNotification";
import VenueNotification from "@/view/pages/view/practical-exam/exam-day/notification/VenueNotification";
const enrolmentYear=new EnrolmentYearService();
const category = new CategoryService();
const instrument = new InstrumentService();
const examSession = new ExamSession();
const syllabus = new SyllabusService();
const examDay=new ExamDayService();
const score = new ScoreService();
const customFilterLink = new CustomFilterLinkService();
const location = new LocationService();
const examiner = new ExaminerService();
const supervisor = new SupervisorService();

export default {
  components: {SupervisorNotification, ExaminerNotification, AccountHolderNotification, VenueNotification},
  validations:{
    custom_filter:{
      name: {required}
    }
  },
  data(){
    return{
      menuStartDate: false,
      menuEndDate: false,
      search:{
        start_date: '',
        end_date: '',
        info:'',
        is_active:'',
        score_id:'',
        exam_session_id: '',
        location_id:'',
        examiner_id:'',
        supervisor_id:'',
        type:'',
        enrolment_year_id:'',
        timetable:'',
        selectedFields: [],
        search_fields: [],
        search_conditions: [],
        search_values: [],
        notification_status:'',
        report_status:'',
        status:'',
        is_private: '',
        sort_by_date: ''
      },
      notifications: [
        {name: 'Enroller pending', value: 'is_account_holder_not_notified'},
        {name: 'Enroller notified', value: 'is_account_holder_notified'},
        {name: 'Examiner pending', value: 'is_examiner_not_notified'},
        {name: 'Examiner notified', value: 'is_examiner_notified'},
        {name: 'Supervisor pending', value: 'is_supervisor_not_notified'},
        {name: 'Supervisor notified', value: 'is_supervisor_notified'},

      ],

      report_statuses: [
        {name: 'Pending', value: 'is_result_not_added'},
        {name: 'Submitted', value: 'is_result_added'},
        {name: 'Printed', value: 'is_result_printed'},
        {name: 'Not printed', value: 'is_result_not_printed'},
        {name: 'Sent', value: 'is_result_dispatched'},
        {name: 'Not sent', value: 'is_result_not_dispatched'},
      ],
      status: [
        {name: 'Active', value: 'is_active'},
        {name: 'Confirmed', value: 'is_confirmed'},
        {name: 'Not confirmed', value: 'is_not_confirmed'},
        {name: 'Inactive', value: 'is_not_active'},
      ],
      yes_no_status: [
      {name: 'Yes', value: 1},
      {name: 'No', value: 0},
    ],
      types: [
        {name: 'Consecutive',value:'sequential'},
        {name: 'Simultaneous',value:'constant'},
        {name: 'Manual',value:'manual'},
      ],
      sessionPrivacyTypes:[
        {
          name: 'Yes',
          value: 1
        },
        {
          name: 'No',
          value: 0
        },
      ],
      exam_days:[],
      total: null,
      perPage: null,
      page: null,
      scores:[],
      search_categories:[],
      search_instruments:[],
      search_syllabuses:[],
      loading: true,
      isSessionLoading: false,
      isInstrumentLoading:false,
      isSyllabusLoading:false,
      enrolment_years:[],
      currentYear:new Date().getFullYear(),
      year:{},
      currentUserDetail:{},
      sessionSearch: null,
      examinerSearch: null,
      examiners:[],
      supervisorSearch: null,
      supervisors:[],
      exam_sessions: [],
      locations: [],
      timetables: [
        {value:'today',name:'Today'},
        {value:'yesterday',name:'Yesterday'},
        {value:'current_week',name:'This week'},
        {value:'last_week',name:'Last week'},
        {value:'current_month',name:'Current month'},
        {value:'last_month',name:'Last month'},
        {value:'completed',name:'Completed'},
        {value:'upcoming',name:'Upcoming'},
        {value:'all',name:'All'},
      ],
      searchFieldDialog: false,
      examDayFilters: [],
      examDayFiltersArray: [],
      filters:[{
        search_fields:'',
        search_conditions:'',
        search_values:'',
      }],
      rows: [],
      conditionFilters: [
        {text: 'Equals To', value: 'equals_to'},
        {text: 'Less Than', value: 'less_than'},
        {text: 'Less Than Equals To', value: 'less_than_equals_to'},
        {text: 'Greater Than', value: 'greater_than'},
        {text: 'Greater Than Equals To', value: 'greater_than_equals_to'},
        {text: 'Contains', value: 'contains'},
      ],
      selectFieldDialog: false,
      selectedFields: [],
      isExportingExcel: false,
      isExporting: false,
      customFilters: '',
      saveLinkLoading: false,
      showSavedSearch: false,
      showSaveDialog: false,
      saveType: '',
      custom_filter:{
        name: ''
      },
      available_actions:[
        {text: 'Notify enroller', value: 'notify_enroler'},
        {text: 'Notify examiner', value: 'notify_examiner'},
        {text: 'Notify supervisor', value: 'notify_supervisor'},
        {text: 'Mark as confirmed', value: 'mark_as_confirmed'},
        {text: 'Mark as not confirmed', value: 'mark_as_not_confirmed'},
      ],
      exam_day_ids: [],
      checkAllExamDaysFlag: false,
      bulkActionLoading: false,
      bulk_action:'',
      sortByActions:[
        {
          name: 'None',
          value: ''
        },
        {
          name: 'Newest to oldest',
          value: 'desctoasc'
        },
        {
          name: 'Oldest to newest',
          value: 'asctodesc'
        }
      ]
    }
  },
  methods:{
    viewExaminerTimeTablePdf(examDayId){
      examDay
      .viewExaminerTimeTablePdf(examDayId)
    },
    viewSupervisorTimeTablePdf(examDayId){
      examDay
      .viewSupervisorTimeTablePdf(examDayId)
    },
    manageSchedule(examDayId){
      this.$router.push({
        name:'exam-day-scheduler',
        params:{examDayId:examDayId}
      })
    },
    viewCandidate(examDayId){
      this.$router.push({
        name:'exam-day-scheduler-candidate',
        params:{examDayId:examDayId}
      })
    },
    createOrExamDay(examDayId){
      if(examDayId==null || examDayId==undefined)
        this.$router.push({
          name:"exam-day-create",
          params: {examDayId:examDayId}
        });
      else
        this.$router.push({
          name:'exam-day-edit',
          params:{examDayId:examDayId}
        })
    },
    getAllExamDays(page){
      this.loading = true;
      if(page)
      {
        this.page = page;
      }
      examDay
          .paginate(this.search,this.page)
          .then(response => {
            this.exam_days=response.data.data;
            this.page = response.data.meta.current_page;
            this.total = response.data.meta.total;
            this.perPage = response.data.meta.per_page;
            this.exam_day_ids = [];
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
          });
    },
    getAllExamSessions(){
      this.isSessionLoading = true;
      examSession
      .getByEnrolmentYear({'enrolment_year_id':this.search.enrolment_year_id, score_id:this.search.score_id})
      .then((response) => {
        this.isSessionLoading = false;
        this.exam_sessions = response.data.examSessions;
      })
    },
    changeEnrolmentYear(){
      this.getAllExamSessions();
    },
    sortExamDays(){
      this.getAllExamDays(1);
    },
    searchExamDays(){
      this.getAllExamDays(1);
    },
    deleteExamDay(item, hasCandidate){
      if(hasCandidate){
        this.$snotify.error('Please remove all candidates from this Schedule before deleting.');
        return false;
      }else{
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            examDay
                .delete(item)
                .then((response) => {
                  this.getAllExamDays();
                  this.$snotify.success("Schedule deleted");
                })
                .catch((err) => {
                  this.$snotify.error("Oops something went wrong");
                });
          }
        },
      });
      }
    },
    formatFilterName(name){
      if(name){
        name = name.replace("exam_days^","");
        name = name.replace("users^","");
        name = name.replace("exam_sessions^","");
        name = name.replace("rooms^","");
        name = name.replace("venues^","");
        name = name.replace("locations^","");
        name = name.replace("scores^","");
        name = name.replace("enrolment_years^","");
        name = name.replace(/_/g," ");
        return name.charAt(0).toUpperCase()+name.slice(1)
      }
    },
    resetAppliedFilter(){
      this.filters = [{
        search_fields:'',
        search_conditions:'',
        search_values:'',
      }];

      this.search.search_fields = [];
      this.search.search_values = [];
      this.search.search_conditions = [];

      this.applyFilter();
    },
    sendAccountHolderNotification(examDay){
      this.$refs['account-holder-notification'].showDialog(examDay);
    },
    sendExaminerNotification(examDay){
      this.$refs['examiner-notification'].showDialog(examDay);
    },
    sendSupervisorNotification(examDay){
      this.$refs['supervisor-notification'].showDialog(examDay);
    },
    sendVenueNotification(examDay){
      this.$refs['venue-notification'].showDialog(examDay);
    },
    sendNotification(id, userFor){
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            let data = {
              userFor: userFor
            }
            examDay
                .sendNotification(id, data)
                .then((response) => {
                  if(response.data.status == "OK") {
                    this.$snotify.success("Schedule Notification Sent ");
                  }
                })
                .catch((err) => {
                  this.$snotify.error("Oops something went wrong");
                });
          }
        },
      });
    },
    getAllScore(){
      score
          .getScores()
          .then(response => {
            this.scores = response.data.scores;
          })
          .catch((err) => {

          });
    },
    getAllCategories() {
      category
          .getFilterData()
          .then(response => {
            this.search_categories = response.data.categories;
          })
          .catch((err) => {
          });
    },
    getAllInstruments() {
      let data = {
        category_id:this.search.category_id
      }
      this.isInstrumentLoading = true;
      instrument
          .getFilterData(data)
          .then(response => {
            this.search_instruments = response.data.instruments;
            this.isInstrumentLoading = false;
          })
          .catch((err) => {
          });
    },
    getAllSyllabuses() {
      let data = {
        instrument_id:this.search.instrument_id
      }
      this.isSyllabusLoading = true;
      syllabus
          .getFilterData(data)
          .then(response => {
            this.search_syllabuses = response.data.syllabuses;
            this.isSyllabusLoading =false;
          })
          .catch((err) => {
          });
    },

    getAllEnrolmentYears() {
      enrolmentYear
      .all()
      .then(response =>{
        this.enrolment_years=response.data;
        this.year = this.enrolment_years.filter(item => item.year == this.currentYear);
        if(this.currentUser.access_type=='score') {
          this.search.enrolment_year_id = this.currentUser.score_enrolment_year;
          this.getAllExamDays();
        }else{
          if(this.year.length>0){
            this.search.enrolment_year_id=this.year[0].id;
            this.getAllExamDays();
          }else  {
            this.getAllExamDays();
          }
        }
        this.getAllExamSessions();
      })
    },

    getAllLocations() {
      location.all().then(response => {
        this.locations = response.data.locations;
      });
    },
    getScoreLocations() {
      location.all(this.search).then(response => {
        this.locations = response.data.locations;
      });
    },
    markAsComplete(examDayId){
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            examDay
            .markAsComplete(examDayId)
            .then((response) => {
              this.$snotify.success('Marked as complete');
              this.getAllExamDays();
            })
            .catch((err) => {
              this.$snotify.error('Something went wrong');
            })
          }
        }
      })
    },
    viewCandidateExamResult(examDayId){
      this.$router.push({
        name:'exam-day-candidate-practical-exam-result',
        params:{examDayId:examDayId}
      })
    },
    // filter and exports
    applyFilter(){
      this.formatExportData();
      this.getAllExamDays();
      this.searchFieldDialog=false;
    },
    formatExportData(){
      this.search.selectedFields = this.selectedFields;
      let search_conditions =[];
      let search_fields = [];
      let search_values = [];

      this.filters.forEach((filter)=>{
        if(filter.search_conditions)
          search_conditions.push(filter.search_conditions);
        if(filter.search_fields)
          search_fields.push(filter.search_fields);
        if(filter.search_values)
          search_values.push(filter.search_values);
      });

      this.search.search_conditions = search_conditions;
      this.search.search_fields = search_fields;
      this.search.search_values = search_values;
    },
    addFilter(){
      this.searchFieldDialog = true;
    },
    closeSelectFieldDialog(){
      this.filters = [{
        search_fields:'',
        search_conditions:'',
        search_values:'',
      }];
      this.search = {
        selectedFields: [],
        search_fields: [],
        search_conditions: [],
        search_values: [],
      }
      this.searchFieldDialog = false;
      this.getAllExamDays();
      this.exam_day_ids = [];
    },
    getExportFilters(){
      examDay
      .getExportFilters()
      .then((response) => {
        this.examDayFilters = response.data.examDayFilters;
        let tempExamDayFilters = response.data.examDayFilters;
        tempExamDayFilters =  Object.entries(tempExamDayFilters);

        tempExamDayFilters.forEach(([key, value]) => {
          let data = {
            text: key,
            value: value
          };
          this.examDayFiltersArray.push(data);
        });
      }).catch((err) => {

      });
    },
    addFilterColumn(){
      this.filters.push({
        search_fields:'',
        search_condition:'',
        search_values:'',
      });
    },
    removeFilterColumn(index){
      this.filters.splice(index, 1);
    },
    showSelectField(){
      this.selectFieldDialog = true;
    },
    closeExcelSelectFieldDialog(){
      this.selectFieldDialog = false;
      this.search.selectedFields = [];
      this.selectedFields = [];
    },
    exportToExcel(){
      this.isExportingExcel = true;
      this.formatExportData();

      if(this.exam_day_ids.length > 0){
        this.search.examDayIds = this.exam_day_ids;
      }

      examDay
      .exportToExcel(this.search)
      .then(response => {
        this.$snotify.success('Export added to queue. You will receive an email upon completion');
        this.selectedFields = [];
        this.search.selectedFields = [];
      })
      .catch(error => {

      })
      .finally(() => {
        this.isExportingExcel = false;
        this.closeSelectFieldDialog();
      })
    },
    exportToCsv(){
      this.isExporting = true;
      this.formatExportData();

      if(this.exam_day_ids.length > 0){
        this.search.examDayIds = this.exam_day_ids;
      }

      examDay
      .exportToCsv(this.search)
      .then(response => {
        this.$snotify.success('Export added to queue. You will receive an email upon completion');
        this.selectedFields = [];
        this.search.selectedFields = [];
      })
      .catch(error => {

      })
      .finally(() => {
        this.isExporting = false;
        this.closeSelectFieldDialog();
      })
    },

    saveLink(type){
      this.saveType = type;
      this.showSaveDialog = true;
    },

    saveLinkForMe(){
      this.$v.custom_filter.$touch()
      if(this.$v.custom_filter.$error){
        setTimeout(() => {
          this.$v.$reset()
        }, 3000)
      }
      else {
        this.saveLinkLoading = true;

        if(this.$route.params &&  this.$route.params.search){
          this.search['id']=this.$route.params.search;
        }
        
        let completeUrl = examDay.getCompleteSearchUrl(this.search,this.page)
        let saveLinkData = {
          name: this.custom_filter.name,
          type: 'exam_day',
          is_private: 1,
          url : completeUrl,
          query_params: this.search,
          export_fields: this.selectedFields,
          user_id: this.currentUser.id,
          score_id: this.currentUser.access_type == 'score' ? this.currentUser.score_id : ''
        }
        this.saveSearchUrl(saveLinkData);
      }
    },

    saveLinkForAll(){
      this.$v.custom_filter.$touch()
      if(this.$v.custom_filter.$error){
        setTimeout(() => {
          this.$v.$reset()
        }, 3000)
      }
      else {
        this.saveLinkLoading = true;

        if(this.$route.params &&  this.$route.params.search){
          this.search['id']=this.$route.params.search;
        }
        
        let completeUrl = examDay.getCompleteSearchUrl(this.search,this.page)
        let saveLinkData = {
          name: this.custom_filter.name,
          type: 'exam_day',
          is_private: 0,
          url : completeUrl,
          query_params: this.search,
          export_fields: this.selectedFields,
          user_id: this.currentUser.id,
          score_id: this.currentUser.access_type == 'score' ? this.currentUser.score_id : ''
        }

        this.saveSearchUrl(saveLinkData);
      }
    },

    saveSearchUrl(data){
      
      customFilterLink
      .store(data)
      .then((res) => {
        this.$snotify.success('Search saved')
      })
      .catch((err) => {
        this.$snotify.error("Oops something went wrong");
      })
      .finally(() => {
        this.saveLinkLoading = false;
        this.cancelSaveSearch();
        this.getAllCustomFilterLinks();
      })
    },

    cancelSaveSearch(){
      this.showSaveDialog = false;
      this.custom_filter = {
        name: ''
      };
    },

    getAllCustomFilterLinks(){
      let filter = {
        type: 'exam_day',
      };

      customFilterLink
      .all(filter)
      .then((res) => {
        this.customFilters = res.data.data;
      })
      .catch((err) => {
        
      })
    },
    deleteCustomFilter(searchId){
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            customFilterLink
            .delete(searchId)
            .then((response) => {
              this.getAllCustomFilterLinks();
              this.$snotify.success("Filter deleted");
            })
            .catch((err) => {
              this.$snotify.error("Something went wrong");
            });
          }
        }
      })
    },
    openSavedSearch(searchId){
      this.resetAppliedFilter();

      customFilterLink
      .show(searchId)
      .then((res)=>{        
        this.filters = res.data.data.formatted_query_params;
        this.selectedFields = res.data.data.export_fields_formatted;
        this.applyFilter();
      })
      .catch((err) => {

      });
    },

    checkAllExamDays(){
      if (this.checkAllExamDaysFlag) {
        this.exam_days.forEach(item => {
          this.exam_day_ids.push(item.id);
         /* if(item.is_confirmed)
          {

          }*/

        });
      } else {
        this.exam_day_ids = [];
      }
    },

    performBulkAction()
    {
      if(this.bulk_action)
      {
        let data ={
          bulk_action:this.bulk_action,
          exam_day_ids:this.exam_day_ids,
        }
        this.bulkActionLoading =true;
        examDay
            .bulkAction(data)
            .then((response) => {
              this.exam_day_ids = [];

              this.getAllExamDays();
              if (this.bulk_action=='notify_enroler')
              {
                this.$snotify.success("Enroller notifications sent");
              }

              if (this.bulk_action=='notify_examiner')
              {
                this.$snotify.success("Examiner notifications sent");
              }

              if (this.bulk_action=='notify_supervisor')
              {
                this.$snotify.success("Supervisor notifications sent");
              }
              if (this.bulk_action=='mark_as_confirmed')
              {
                this.$snotify.success("Schedule(s) marked as confirmed");
              }
              if (this.bulk_action=='mark_as_not_confirmed')
              {
                this.$snotify.success("Schedule(s) marked as not confirmed");
              }

              this.bulk_action = '';
              this.exam_day_ids=[];
              this.bulkActionLoading =false;
            })
            .catch((err) => {
              this.bulkActionLoading =false;
              this.$snotify.error("Something went wrong");
            });
      }
      else
      {
        this.$snotify.error('Please select action');
      }
    },

    markReportStatus(item){
      if(!item.is_reports_received){
        this.$confirm({
          message: `Mark as report received? `,
          button: {
            no: "No",
            yes: "Yes",
          },
          callback: (confirm) => {
            if (confirm) {
              examDay
            .markReportReceived(item.id)
            .then((res) => {
              this.$snotify.success('Marked as report received');
              this.getAllExamDays();
            })
            .catch((err) => {
              this.$snotify.error('Something went wrong.');
            })   
            }
          },
        });
      }else{
        this.$confirm({
          message: `Mark as report received? `,
          button: {
            no: "No",
            yes: "Yes",
          },
          callback: (confirm) => {
            examDay
            .markReportNotReceived(item.id)
            .then((res) => {
              this.$snotify.success('Marked as report not received');
              this.getAllExamDays();
            })
            .catch((err) => {
              this.$snotify.error('Something went wrong.');
            })
          },
        });
      }
    }
  },
  mounted() {
    this.getCurrentUser();
    this.getAllScore();
    this.getAllCategories();
    this.getAllInstruments();
    this.getAllSyllabuses();
    this.getAllEnrolmentYears();
    this.getAllLocations();
    this.getExportFilters();
    this.getAllCustomFilterLinks();
  },
  computed:{
    currentUser() {
      return this.$store.getters.currentUser;
    },
  },
  watch:{
    sessionSearch(val) {

    if(val)
    {
      let data = {
        name:val,
        enrolment_year_id:this.search.enrolment_year_id,
        score_id:this.search.score_id
      };
      examSession
          .search(data)
          .then((response) => {
            response.data.exam_sessions.map((session) => {
              let data = session;
              data.display_text = session.name ;
              this.exam_sessions.push(data);
            });
          })
          .catch((err) => {

          })
          .finally(() => (this.isLoading = false));
    }
    else
    {
      this.exam_sessions = [];
    }


    },

    supervisorSearch(val) {
      let data = {
        info: val,
        score_id:this.currentUser.access_type=='score'?this.currentUser.score_id:this.search.score_id
      }
      supervisor
          .search(data)
          .then((response) => {
            this.supervisors = response.data.data;
          })
          .catch(err => {

          })
          .finally(() => (this.isLoading = false))
    },

    examinerSearch(val){
      let data={
        info:val,
        score_id:this.currentUser.access_type=='score'?this.currentUser.score_id:this.search.score_id
      }
      examiner
          .search(data)
          .then((response) => {
            this.examiners=response.data.data;
          })
          .catch(err => {

          })
          .finally(() => (this.isLoading = false))
    },
  },
}
</script>